import logo from '../assets/images/logo.png';
import logoCommerce from '../assets/images/logo-commerce.png';
import logoCrafts from '../assets/images/logo-crafts.png';
import logoSimple from '../assets/images/logo-simple.png';
import header from '../assets/images/header.jpg';
import commerce from '../assets/images/commerce.jpg';
import crafts from '../assets/images/crafts.jpg';
import craftsmenMasterLevelOne from '../assets/images/craftsmen-master-level-one.jpg';
import craftsmenMasterLevelTwo from '../assets/images/craftsmen-master-level-two.jpg';
import footer from '../assets/images/footer-logo.png';
import congrat from '../assets/images/congrat.png';
import favicon from '../assets/images/favicon.png';

export const IMAGES = {
	logo,
	logoCommerce,
	logoCrafts,
	logoSimple,
	header,
	commerce,
	crafts,
	'craftsmen-master-level-one': craftsmenMasterLevelOne,
	'craftsmen-master-level-two': craftsmenMasterLevelTwo,
	footer,
	congrat,
	favicon
};
