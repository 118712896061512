import React from 'react';
import { IMAGES } from '../../constants/images';
import { Typography } from '@mui/material';
import { ResetScoreButton } from './reset-score-button';
import { versionsFormater } from 'common-frontend/utils/formatters';
import { SHOW_RESET_LEARNING_PROGRESS } from 'common-frontend/constants/env';

export const Footer = () => {
	return (
		<footer>
			<div className="footer-logo-container">
				<img src={ IMAGES.footer } alt="logo" />
				{SHOW_RESET_LEARNING_PROGRESS && <ResetScoreButton color="white" />}
			</div>
			<div className="versions-container">
				<VersionsMemo />
			</div>
		</footer>
	);
};

const VersionsMemo = React.memo(() => <Typography variant="caption">{ versionsFormater() }</Typography>);
