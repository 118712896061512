import React from 'react';
import { Outlet } from 'react-router-dom';
import { HeaderUser } from './header-user';
import { FooterUser } from './footer-user';
import { BreadcrumbsProvider } from './breadcrumbs';

export const UserLayout = () => {
	return (
		<>
			<div className="page-wrapper">
				<HeaderUser/>
				<BreadcrumbsProvider>
					<Outlet />
				</BreadcrumbsProvider>
			</div>
			<FooterUser />
		</>
	);
};
