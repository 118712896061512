import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { useStores } from 'common-frontend/components/store-provider';
import { REMOVE_RELATIONSHIP } from 'common-frontend/services/api-calls';
import { useTranslation } from 'react-i18next';
import { AcceptPopup } from 'common-frontend/components/accept-popup';
import { courses } from '../../constants/courses';

export const RestartScenarioButton = ({ ...props }) => {
	const { t } = useTranslation();
	const [ removeUserPopup, setRemoveUserPopup ] = useState(false);
	const [ localLoader, setLocalLoader ] = useState(false);
	const { WalletStore } = useStores();

	const removeRelationship = useCallback(() => {
		setLocalLoader(true);
		setRemoveUserPopup(false);

		REMOVE_RELATIONSHIP().finally(() => {
			WalletStore.reset();

			courses.forEach(course => {
				sessionStorage.removeItem(`${course}_register_button_clicked`);
			});

			setLocalLoader(false);
		});
	}, []);

	return (
		<>
			<Button id="remove-user-button" disabled={localLoader} variant="contained" onClick={() => setRemoveUserPopup(true)} {...props}>{t('footer-user.remove-user.button')}</Button>
			<AcceptPopup id='remove-user' title={t('footer-user.remove-user.title')} text={t('footer-user.remove-user.dialog')}
				isOpen={ removeUserPopup } isDanger isWarning confirmText={ t('footer-user.remove-user.confirm') }
				onClose={ () => { setRemoveUserPopup(false); }}
				action={ removeRelationship } />
		</>
	);
};