import React, { useCallback } from 'react';
import { useStores } from 'common-frontend/components/store-provider';
import { Button, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { PATHS } from '../../constants/paths.js';

export const UserPage = observer(() => {
	const { UserStore } = useStores();
	const { t } = useTranslation();
	const { user } = UserStore;
	const navigate = useNavigate();
	const location = useLocation();

	const handleBackClick = () => {
		navigate(location.key === 'default' ? PATHS.crafts : -1);
	};

	const birthDateConcat = useCallback((user) => {
		if (user?.birthDay && user?.birthMonth && user?.birthYear) {
			const day = user.birthDay < 10 ? `0${user.birthDay}` : user.birthDay;
			const month = user.birthMonth < 10 ? `0${user.birthMonth}` : user.birthMonth;
			return `${day}.${month}.${user.birthYear}`;
		}
		return '';
	}, []);

	const generateSpaceSeparatedString = useCallback((...values) => {
		return values.map(v => v || '_empty_').join(' ').concat(' ').replaceAll('_empty_ ', '');
	}, []);

	const birthAddressConcat = useCallback((user) => {
		return generateSpaceSeparatedString(user?.birthPlace, user?.birthCity, user?.birthState, user?.birthCountry);
	}, []);

	const addressConcat = useCallback((user) => {
		return generateSpaceSeparatedString(user?.street, user?.house, user?.city, user?.state, user?.country, user?.zip);
	}, []);

	return (
		<div className="user-page">
			<section className={user?.name ? 'active' : ''}>
				<div className="title">{t('user.section-user-information.title')}</div>
				<div className="content">
					<div className="form-field">
						<Typography>{t('user.section-user-information.first-name')}:</Typography>
						<input type="text" placeholder={t('user.section-user-information.first-name')} value={user?.name || '' } readOnly={ true }/>
					</div>
					<div className="form-field">
						<Typography>{t('user.section-user-information.last-name')}:</Typography>
						<input type="text" placeholder={t('user.section-user-information.last-name')} value={ user?.surname || '' } readOnly={ true }/>
					</div>
					<div className="form-field">
						<Typography>{t('user.section-user-information.address')}:</Typography>
						<input type="text" placeholder={t('user.section-user-information.address')} value={ addressConcat(user) } readOnly={ true }/>
					</div>
					<div className="form-field">
						<Typography>{t('user.section-user-information.birth-date')}:</Typography>
						<input type="text" placeholder={t('user.section-user-information.birth-date')} value={ birthDateConcat(user) } readOnly={ true }/>
					</div>
					<div className="form-field">
						<Typography>{t('user.section-user-information.birth-address')}:</Typography>
						<input type="text" placeholder={t('user.section-user-information.birth-address')} value={ birthAddressConcat(user) } readOnly={ true }/>
					</div>
				</div>
			</section>
			<Button id="back-button" variant="contained" size="large" onClick={handleBackClick}>{t('back')}</Button>
		</div>
	);
});
