import { createTheme } from '@mui/material';

export const THEME = createTheme({
	palette: {
		primary: {
			light: '#004b76',
			main: '#004b76',
			dark: '#739CB4',
			text: '#fff',
		},
		secondary: {
			light: '#fff',
			main: '#408FE5',
			dark: '#408FE5',
			text: '#222',
		},
		blue: {
			background: '#3e7cb1',
			border: '#2e6ca1',
		},
		gray: {
			background: '#b1b1b1',
			border: '#dfdfdf',
		},
		white: {
			main: '#fff',
			contrastText: '#004b76'
		},
	},
	typography: {
		fontSize: '17px',

		h1: {
			fontSize: '32px',
			color: '#408FE5',
		},
		h2: {
			fontSize: '25px',
			color: '#408FE5',
		},
		h3: {
			fontSize: '18px',
			color: '#408FE5',
		},
		h4: {
			fontSize: '27px',
			color: '#fff'
		},
		h5: {
			fontSize: '27px',
		}
	},
	variables: {
		maxWidth: '1100px'
	}
});
