import React, { useCallback, useState, useMemo } from 'react';
import { Button } from '@mui/material';
import { useStores } from 'common-frontend/components/store-provider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionsPopup } from 'common-frontend/components/actions-popup';
import { observer } from 'mobx-react';
import { RESET_SCORE } from '../../services/api-calls';
import { courses } from '../../constants/courses';
import { PATHS } from '../../constants/paths';

export const ResetScoreButton = observer(({ ...props }) => {
	const navigate = useNavigate();
	const { WalletStore, UserStore } = useStores();
	const { getScore, scores } = WalletStore;
	const { user } = UserStore;

	const [ localLoader, setLocalLoader ] = useState(false);
	const [ resetScorePopup, setResetScorePopup ] = useState(false);
	const { t } = useTranslation();

	const resetScore = useCallback((course) => {
		setLocalLoader(true);
		setResetScorePopup(false);

		RESET_SCORE(course)
			.then(() => { navigate(PATHS.crafts); })
			.finally(() => { setLocalLoader(false); });
	}, []);

	const actions = useMemo(() => {
		const course = getScore(WalletStore.currentCourse) > 0 ? WalletStore.currentCourse : WalletStore.latestCompleteCourse;

		const array = [
			{
				id: course,
				name: t('footer-user.reset-score.reset-course', { course: t(`courses.${course}.name`) }),
				func: () => { resetScore(course); },
				isDanger: true
			}
		];

		if (course && course !== courses[0] && getScore(course) > 0) {
			array.push({
				id: 'all',
				name: t('footer-user.reset-score.reset-all'),
				func: () => { resetScore('all'); },
				isDanger: true
			});
		}

		return array;
	}, [ scores, t ]);

	return user && WalletStore.coursesInProgress && (
		<>
			<Button id="reset-score-button" disabled={ localLoader}  variant="contained" onClick={ () => setResetScorePopup(true) } { ...props }>{t('footer-user.reset-score.button')}</Button>
			<ActionsPopup
				id="reset-score-popup"
				title={t('footer-user.reset-score.button')}
				text=""
				isOpen={resetScorePopup}
				onClose={() => setResetScorePopup(false)}
				actions={actions}
			/>
		</>
	);
});