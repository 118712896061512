import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const Commerce = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="commerce-banner"></div>
			<main className="main">
				<Grid container spacing={ 4 }>
					<Grid item lg={ 12 } md={ 12 }>
						<Typography variant="h1" className="mt-32 mb-32">{t('commerce.article-1.title')}</Typography>
						<Typography className="mt-32 mb-32">{t('commerce.article-1.text')}</Typography>
						<Typography variant="h2">{t('commerce.article-2.title')}</Typography>
						<Box className="mt-16 mb-32">
							<Trans i18nKey="commerce.article-2.text"><Typography></Typography><ul><li><Typography></Typography></li></ul></Trans>
						</Box>
					</Grid>
				</Grid>
			</main>
		</>
	);
};
