import React, { useCallback } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { IMAGES } from '../../constants/images';
import { useTranslation } from 'react-i18next';
import { SYNC_LANGUAGE } from 'common-frontend/services/api-calls';
import { observer } from 'mobx-react';
import { useStores } from 'common-frontend/components/store-provider';
import { PATHS } from '../../constants/paths';

export const Header = observer(() => {
	const { pathname } = useLocation();
	const { t, i18n  } = useTranslation();
	const { UserStore, AuthStore } = useStores();
	const { user } = UserStore;
	const { authProfile } = AuthStore;

	const logos = { '/': IMAGES.logo, '/commerce': IMAGES.logoCommerce, '/crafts': IMAGES.logoCrafts };

	const switchLanguage = useCallback((lang) => {
		i18n.changeLanguage(lang); // en || de
		user && SYNC_LANGUAGE();
	}, [ user, i18n ]);

	return (
		<>
			<div className="logo-wrapper">
				<div className="logo-container">
					<img src={ logos[pathname] } alt="logo"/>
				</div>
			</div>
			<div className="nav-container">
				<nav className="nav">
					<NavLink id="home-link" to={ PATHS.home }>{t('nav.home')}</NavLink>
					<NavLink id="commerce-link" to={ PATHS.commerce }>{t('nav.commerce')}</NavLink>
					<NavLink id="crafts-link" to={ PATHS.crafts }>{t('nav.crafts')}</NavLink>
					<div className="language-switch-container">
						<Link className="language-switch" translate="no" onClick={() => { switchLanguage('de'); }}>DE</Link>
						<Link className="language-switch" translate="no" onClick={() => { switchLanguage('en'); }}>EN</Link>
					</div>
					<NavLink id="user-link" to={PATHS.user} disabled={!authProfile}>{t('nav.user')}</NavLink>
				</nav>
			</div>
		</>
	);
});
